<template>
  <b-card title="Reintervention" sub-title="Information about reintervention">
    <b-row>
      <b-col md="6">
        <b-form-group label="Reintervention" label-for="---" label-cols-md="4">
          <v-select
            label="value"
            :options="options"
            v-model="patientInfo.follow_up_reintervention"
            :disabled="readOnly"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-row>
          <b-col cols="12">
            <b-card class="bordered-card" title="MV related reintervention">
              <b-row>
                <b-col md="6">
                  <b-col cols="12">
                    <b-form-group
                      label="MV related reintervention"
                      label-for="mv_related_reintervention"
                      label-cols-md="4"
                    >
                      <v-select
                        v-model="
                          patientInfo.follow_up_mv_related_reintervention
                        "
                        :options="options"
                        :disabled="readOnly"
                      />
                    </b-form-group>
                  </b-col>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <b-col md="12">
                    <b-form-group
                      label="Reintervention Date"
                      label-for="follow_up_mv_related_reintervention_date"
                      label-cols-md="6"
                    >
                      <flat-pickr
                        v-model="
                          patientInfo.follow_up_mv_related_reintervention_date
                        "
                        class="form-control"
                        :config="{ enableTime: false, dateFormat: 'd.m.Y' }"
                        :disabled="readOnly"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-form-group
                      label="Type of Reintervention"
                      label-for="mv_related_reintervention_type"
                      label-cols-md="4"
                    >
                      <v-select
                        v-model="
                          patientInfo.follow_up_mv_related_reintervention_type
                        "
                        :options="optionsMVReinterventionType"
                        :disabled="readOnly"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Transcatheter Type"
                      label-for="follow_up_mv_related_reintervention_type_transcatheter_specify"
                      label-cols-md="4"
                    >
                      <b-form-input
                        id="follow_up_mv_related_reintervention_type_transcatheter_specify"
                        v-model="
                          patientInfo.follow_up_mv_related_reintervention_type_transcatheter_specify
                        "
                        placeholder="Transcatheter Type"
                        :readonly="readOnly"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-form-group
                      label="Main Reason for Reintervention"
                      label-for="mv_reintervention_main_reason"
                      label-cols-md="4"
                    >
                      <v-select
                        v-model="
                          patientInfo.follow_up_mv_reintervention_main_reason
                        "
                        :options="optionsMVReinterventionMainReason"
                        :disabled="readOnly"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Specify Other Reason for Reintervention"
                      label-for="follow_up_mv_related_reintervention_other_specify"
                      label-cols-md="4"
                    >
                      <b-form-textarea
                        id="follow_up_mv_related_reintervention_other_specify"
                        v-model="
                          patientInfo.follow_up_mv_related_reintervention_other_specify
                        "
                        rows="3"
                        placeholder="Specify Other Reason for Reintervention"
                        :readonly="readOnly"
                      />
                    </b-form-group>
                  </b-col>
                </b-col>
                <b-col md="6">
                  <b-col cols="12">
                    <b-form-group
                      label="Dysfunction Type of Reintervention"
                      label-for="mv_related_reintervention_dysfunction_type"
                      label-cols-md="4"
                    >
                      <v-select
                        v-model="
                          patientInfo.follow_up_mv_related_reintervention_dysfunction_type
                        "
                        :options="optionsMVReinterventionDysfunctionType"
                        :disabled="readOnly"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-form-group
                      label="Endocarditis Non-Operated"
                      label-for="mv_endocarditis_non_operated"
                      label-cols-md="4"
                    >
                      <v-select
                        v-model="
                          patientInfo.follow_up_mv_endocarditis_non_operated
                        "
                        :options="options"
                        :disabled="readOnly"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      label="Endocarditis Date"
                      label-for="follow_up_mv_endocarditis_date"
                      label-cols-md="6"
                    >
                      <flat-pickr
                        v-model="patientInfo.follow_up_mv_endocarditis_date"
                        class="form-control"
                        :config="{ enableTime: false, dateFormat: 'd.m.Y' }"
                        :disabled="readOnly"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-form-group
                      label="Thrombosis Non-Operated"
                      label-for="mv_thrombosis_non_operated"
                      label-cols-md="4"
                    >
                      <v-select
                        v-model="
                          patientInfo.follow_up_mv_thrombosis_non_operated
                        "
                        :options="options"
                        :disabled="readOnly"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      label="Thrombosis Date"
                      label-for="follow_up_mv_thrombosis_date"
                      label-cols-md="6"
                    >
                      <flat-pickr
                        v-model="patientInfo.follow_up_mv_thrombosis_date"
                        class="form-control"
                        :config="{ enableTime: false, dateFormat: 'd.m.Y' }"
                        :disabled="readOnly"
                      />
                    </b-form-group>
                  </b-col>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col cols="12">
            <b-card class="bordered-card" title="TV related Reintervention">
              <b-row>
                <b-col md="6">
                  <b-col cols="12">
                    <b-form-group
                      label="TV-Related Reintervention"
                      label-for="tv_related_reintervention"
                      label-cols-md="4"
                    >
                      <v-select
                        v-model="
                          patientInfo.follow_up_tv_related_reintervention
                        "
                        :options="options"
                        :disabled="readOnly"
                      />
                    </b-form-group>
                  </b-col>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-col md="12">
                    <b-form-group
                      label="TV Related Reintervention Date"
                      label-for="follow_up_tv_related_reintervention_date"
                      label-cols-md="6"
                    >
                      <flat-pickr
                        v-model="
                          patientInfo.follow_up_tv_related_reintervention_date
                        "
                        class="form-control"
                        :config="{ enableTime: false, dateFormat: 'd.m.Y' }"
                        :disabled="readOnly"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-form-group
                      label="TV-Related Reintervention Type"
                      label-for="tv_related_reintervention_type"
                      label-cols-md="4"
                    >
                      <v-select
                        v-model="
                          patientInfo.follow_up_tv_related_reintervention_type
                        "
                        :options="optionsTVReinterventionType"
                        :disabled="readOnly"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Transcatheter Procedure Description"
                      label-for="follow_up_tv_related_reintervention_transcatheter_procedure_specify"
                      label-cols-md="4"
                    >
                      <b-form-textarea
                        id="follow_up_tv_related_reintervention_transcatheter_procedure_specify"
                        v-model="
                          patientInfo.follow_up_tv_related_reintervention_transcatheter_procedure_specify
                        "
                        rows="3"
                        placeholder="Transcatheter Procedure Description"
                        :readonly="readOnly"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-form-group
                      label="TV Related Reintervention Dysfunction Type"
                      label-for="tv_related_reintervention_dysfunction_type"
                      label-cols-md="4"
                    >
                      <v-select
                        v-model="
                          patientInfo.follow_up_tv_related_reintervention_dysfunction_type
                        "
                        :options="optionsTVDysfunctionType"
                        :disabled="readOnly"
                      />
                    </b-form-group>
                  </b-col>
                </b-col>
                <b-col md="6">
                  <b-col cols="12">
                    <b-form-group
                      label="TV Reintervention Main Reason"
                      label-for="tv_reintervention_main_reason"
                      label-cols-md="4"
                    >
                      <v-select
                        v-model="
                          patientInfo.follow_up_tv_reintervention_main_reason
                        "
                        :options="optionsTVReinterventionMainReason"
                        :disabled="readOnly"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Other Cardiac Reoperation"
                      label-for="other_cardiac_reoperation"
                      label-cols-md="4"
                    >
                      <v-select
                        v-model="
                          patientInfo.follow_up_other_cardiac_reoperation
                        "
                        :options="options"
                        :disabled="readOnly"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      label="Other Cardiac Reoperation Date"
                      label-for="follow_up_other_cardiac_reoperation_date"
                      label-cols-md="6"
                    >
                      <flat-pickr
                        v-model="
                          patientInfo.follow_up_other_cardiac_reoperation_date
                        "
                        class="form-control"
                        :config="{ enableTime: false, dateFormat: 'd.m.Y' }"
                        :disabled="readOnly"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-form-group
                      label="Specify Other Cardiac Reoperation"
                      label-for="follow_up_other_cardiac_reoperation_specify"
                      label-cols-md="4"
                    >
                      <b-form-textarea
                        id="follow_up_other_cardiac_reoperation_specify"
                        v-model="
                          patientInfo.follow_up_other_cardiac_reoperation_specify
                        "
                        rows="3"
                        placeholder="Specify Other Cardiac Reoperation"
                        :readonly="readOnly"
                      />
                    </b-form-group>
                  </b-col>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { convertDate2Str, dateMask, subtractDate } from "@core/utils/utils";
import { mapState } from "vuex";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    BFormTextarea,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        follow_up_reintervention: "",
        follow_up_mv_related_reintervention: "",
        follow_up_mv_related_reintervention_date: "",

        follow_up_mv_related_reintervention_type: "",
        follow_up_mv_related_reintervention_type_transcatheter_specify: "",

        follow_up_mv_reintervention_main_reason: "",
        follow_up_mv_related_reintervention_other_specify: "",

        follow_up_mv_related_reintervention_dysfunction_type: "",
        follow_up_tv_related_reintervention: "",
        follow_up_tv_related_reintervention_date: "",

        follow_up_tv_related_reintervention_type: "",
        follow_up_tv_related_reintervention_transcatheter_procedure_specify: "",

        follow_up_tv_reintervention_main_reason: "",
        follow_up_tv_related_reintervention_dysfunction_type: "",
        follow_up_other_cardiac_reoperation: "",
        follow_up_other_cardiac_reoperation_date: "",

        follow_up_other_cardiac_reoperation_specify: "",

        follow_up_mv_endocarditis_non_operated: "",
        follow_up_mv_endocarditis_date: "",

        follow_up_mv_thrombosis_non_operated: "",
        follow_up_mv_thrombosis_date: "",
      },
      options: ["yes", "no"],
      optionsMVReinterventionType: ["Surgical", "Transcatheter"],
      optionsMVReinterventionMainReason: [
        "Regurgitation",
        "Stenosis",
        "Endocarditis",
        "Valve thrombosis",
        "Other",
      ],
      optionsMVReinterventionDysfunctionType: ["Structural", "Non Structural"],
      optionsTVReinterventionType: ["Surgical", "Transcatheter"],
      optionsTVReinterventionMainReason: [
        "Regurgitation",
        "Stenosis",
        "Endocarditis",
        "Valve thrombosis",
        "Other",
      ],
      optionsTVDysfunctionType: ["Structural", "Non Structural"],
    };
  },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
  },
  watch: {
    patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v);
      }
    },
  },
  mounted() {
    if (this.patient._id) {
      this.setPatientInfo(this.patient);
    }
  },
  methods: {
    dateMask,
    setPatientInfo(v) {
      this.patientInfo._id = v._id;
      this.patientInfo.follow_up_reintervention = v.follow_up_reintervention;
      this.patientInfo.follow_up_mv_related_reintervention =
        v.follow_up_mv_related_reintervention;
      this.patientInfo.follow_up_mv_related_reintervention_date =
        v.follow_up_mv_related_reintervention_date;

      this.patientInfo.follow_up_mv_related_reintervention_type =
        v.follow_up_mv_related_reintervention_type;
      this.patientInfo.follow_up_mv_related_reintervention_type_transcatheter_specify =
        v.follow_up_mv_related_reintervention_type_transcatheter_specify;

      this.patientInfo.follow_up_mv_reintervention_main_reason =
        v.follow_up_mv_reintervention_main_reason;
      this.patientInfo.follow_up_mv_related_reintervention_other_specify =
        v.follow_up_mv_related_reintervention_other_specify;

      this.patientInfo.follow_up_mv_related_reintervention_dysfunction_type =
        v.follow_up_mv_related_reintervention_dysfunction_type;
      this.patientInfo.follow_up_tv_related_reintervention =
        v.follow_up_tv_related_reintervention;
      this.patientInfo.follow_up_tv_related_reintervention_date =
        v.follow_up_tv_related_reintervention_date;

      this.patientInfo.follow_up_tv_related_reintervention_type =
        v.follow_up_tv_related_reintervention_type;
      this.patientInfo.follow_up_tv_related_reintervention_transcatheter_procedure_specify =
        v.follow_up_tv_related_reintervention_transcatheter_procedure_specify;

      this.patientInfo.follow_up_tv_reintervention_main_reason =
        v.follow_up_tv_reintervention_main_reason;
      this.patientInfo.follow_up_tv_related_reintervention_dysfunction_type =
        v.follow_up_tv_related_reintervention_dysfunction_type;
      this.patientInfo.follow_up_other_cardiac_reoperation =
        v.follow_up_other_cardiac_reoperation;
      this.patientInfo.follow_up_other_cardiac_reoperation_date =
        v.follow_up_other_cardiac_reoperation_date;

      this.patientInfo.follow_up_other_cardiac_reoperation_specify =
        v.follow_up_other_cardiac_reoperation_specify;

      this.patientInfo.follow_up_mv_endocarditis_non_operated =
        v.follow_up_mv_endocarditis_non_operated;
      this.patientInfo.follow_up_mv_endocarditis_date =
        v.follow_up_mv_endocarditis_date;

      this.patientInfo.follow_up_mv_thrombosis_non_operated =
        v.follow_up_mv_thrombosis_non_operated;
      this.patientInfo.follow_up_mv_thrombosis_date =
        v.follow_up_mv_thrombosis_date;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
