<template>
  <b-card title="Clinical" sub-title="Information about clinical">
    <b-row>
      <b-col md="6">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="ASA classification"
              label-for="asa_class"
              label-cols-md="4"
            >
              <b-form-input
                v-model="patientInfo.follow_up_clinical_ASA_classification"
                id="asa_class"
                type="number"
                placeholder="ASA classification"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="CCS angina pectoris"
              label-for="ccs_angina"
              label-cols-md="4"
            >
              <b-form-input
                v-model="patientInfo.follow_up_clinical_CCS_angina_pectoris"
                id="ccs_angina"
                type="number"
                placeholder="CCS angina pectoris"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="NYHA" label-for="NYHA" label-cols-md="4">
              <v-select
                v-model="patientInfo.follow_up_clinical_NYHA"
                label="value"
                :options="options1"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="6 min walk test"
              label-for="walk_test"
              label-cols-md="4"
            >
              <b-form-input
                v-model="patientInfo.follow_up_clinical_6_min_walk_test"
                id="walk_test"
                type="number"
                placeholder="6 min walk test"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Frailty score"
              label-for="frailty_score"
              label-cols-md="4"
            >
              <b-form-input
                v-model="patientInfo.follow_up_clinical_frailty_score"
                id="frailty_score"
                type="number"
                placeholder="Frailty score"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Rhythm" label-for="rhythm" label-cols-md="4">
              <v-select
                v-model="patientInfo.follow_up_clinical_rhythm"
                label="value"
                :options="options3"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Meld score"
              label-for="meld_score"
              label-cols-md="4"
            >
              <div class="vertical-align">
                {{ patientInfo.follow_up_clinical_meld_score }}
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mt-2">
            <b-form-group
              label="Renal failure"
              label-for="renal_failure"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.follow_up_clinical_renal_failure"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Grade" label-for="grade" label-cols-md="4">
              <v-select
                v-model="patientInfo.follow_up_clinical_renal_failure_detail"
                label="value"
                :options="options2"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Antiplatelets Follow-Up"
              label-for="antiplatelets_follow_up"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.follow_up_antiplatelets"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Oral Anticoagulants Follow-Up"
              label-for="oral_anticoagulants_follow_up"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.follow_up_oral_anticoagulants"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Type of Oral Anticoagulants"
              label-for="oral_anticoagulants_type"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.follow_up_oral_anticoagulants_type"
                :options="optionsOralAnticoagulantsType"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Other Type"
              label-for="follow_up_oral_anticoagulants_other_type"
              label-cols-md="4"
            >
              <b-form-input
                id="follow_up_oral_anticoagulants_other_type"
                v-model="patientInfo.follow_up_oral_anticoagulants_other_type"
                placeholder="Other Type"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6">
        <b-card class="bordered-card" title="Right heart failure">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Right heart failure"
                label-for="right_heart_failure"
                label-cols-md="4"
              >
                <v-select
                  v-model="
                    patientInfo.follow_up_lab_clinical_right_heart_failure
                  "
                  label="value"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Odema" label-for="odema" label-cols-md="4">
                <v-select
                  v-model="patientInfo.follow_up_lab_clinical_odema"
                  label="value"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Ascites"
                label-for="ascites"
                label-cols-md="4"
              >
                <v-select
                  v-model="patientInfo.follow_up_lab_clinical_ascites"
                  label="value"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Venous congestion"
                label-for="venous"
                label-cols-md="4"
              >
                <v-select
                  v-model="patientInfo.follow_up_lab_clinical_venous_congestion"
                  label="value"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Pleural effusion"
                label-for="pleural effusion"
                label-cols-md="4"
              >
                <v-select
                  v-model="patientInfo.follow_up_lab_clinical_pleural_effusion"
                  label="value"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapState } from "vuex";
import { getMeldScoreForDischargeAndFollowup } from "@/@core/utils/utils";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    vSelect,
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        follow_up_clinical_meld_score: "",
        follow_up_clinical_ASA_classification: "",
        follow_up_clinical_CCS_angina_pectoris: "",
        follow_up_clinical_NYHA: "",
        follow_up_clinical_6_min_walk_test: "",
        follow_up_clinical_frailty_score: "",
        follow_up_clinical_renal_failure: "",
        follow_up_clinical_renal_failure_detail: "",
        follow_up_clinical_rhythm: "",
        follow_up_lab_clinical_right_heart_failure: "",
        follow_up_lab_clinical_odema: "",
        follow_up_lab_clinical_ascites: "",
        follow_up_lab_clinical_venous_congestion: "",
        follow_up_lab_clinical_pleural_effusion: "",
        follow_up_antiplatelets: "",
        follow_up_oral_anticoagulants: "",
        follow_up_oral_anticoagulants_type: "",
        follow_up_oral_anticoagulants_other_type: "",
      },
      options: ["yes", "no"],
      options1: ["I", "II", "III", "IV"],
      options2: [
        "1 GFR: >90ml/min/1,73m^2",
        "2 GFR: 60-89ml/min/1,73m^2",
        "3 GFR: 30-59ml/min/1,73m^2",
        "4 GFR: 15-29ml/min/1,73m^2",
        "5 GFR: <15ml/min/1,73m^2",
      ],
      options3: [
        "Sinus rhythm",
        "AV block I",
        "AV block II",
        "AV block III",
        "A fib",
        "Sick sinus",
      ],
      optionsOralAnticoagulantsType: ["VKA", "NOAC", "Other"],
    };
  },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
    follow_up_clinical_meld_score() {
      return getMeldScoreForDischargeAndFollowup(
        this.patient.follow_up_lab_creatinin,
        this.patient.follow_up_lab_bilirubin,
        this.patient.follow_up_lab_inr
      );
    },
  },
  watch: {
    patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v);
      }
    },
    follow_up_clinical_meld_score(v, ov) {
      this.patientInfo.follow_up_clinical_meld_score = v;
    },
  },
  mounted() {
    if (this.patient._id) {
      this.setPatientInfo(this.patient);
    }
  },
  methods: {
    setPatientInfo(v) {
      this.patientInfo._id = v._id;
      this.patientInfo.follow_up_clinical_meld_score =
        v.follow_up_clinical_meld_score;
      this.patientInfo.follow_up_clinical_ASA_classification =
        v.follow_up_clinical_ASA_classification;
      this.patientInfo.follow_up_clinical_CCS_angina_pectoris =
        v.follow_up_clinical_CCS_angina_pectoris;
      this.patientInfo.follow_up_clinical_NYHA = v.follow_up_clinical_NYHA;
      this.patientInfo.follow_up_clinical_6_min_walk_test =
        v.follow_up_clinical_6_min_walk_test;
      this.patientInfo.follow_up_clinical_frailty_score =
        v.follow_up_clinical_frailty_score;
      this.patientInfo.follow_up_clinical_renal_failure =
        v.follow_up_clinical_renal_failure;
      this.patientInfo.follow_up_clinical_renal_failure_detail =
        v.follow_up_clinical_renal_failure_detail;
      this.patientInfo.follow_up_clinical_rhythm = v.follow_up_clinical_rhythm;
      this.patientInfo.follow_up_lab_clinical_right_heart_failure =
        v.follow_up_lab_clinical_right_heart_failure;
      this.patientInfo.follow_up_lab_clinical_odema =
        v.follow_up_lab_clinical_odema;
      this.patientInfo.follow_up_lab_clinical_ascites =
        v.follow_up_lab_clinical_ascites;
      this.patientInfo.follow_up_lab_clinical_venous_congestion =
        v.follow_up_lab_clinical_venous_congestion;
      this.patientInfo.follow_up_lab_clinical_pleural_effusion =
        v.follow_up_lab_clinical_pleural_effusion;
      this.patientInfo.follow_up_antiplatelets = v.follow_up_antiplatelets;
      this.patientInfo.follow_up_oral_anticoagulants =
        v.follow_up_oral_anticoagulants;
      this.patientInfo.follow_up_oral_anticoagulants_type =
        v.follow_up_oral_anticoagulants_type;
      this.patientInfo.follow_up_oral_anticoagulants_other_type =
        v.follow_up_oral_anticoagulants_other_type;
    },
  },
};
</script>

<style>
</style>
